import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { RegisterButton } from '../../UI'
import { size } from '../../../utils/styled-components/breakpoints'
import HeroLine from './icons/hero-line'
import HeroImage from '../../../images/banking/hero-image.png'
import Earnings from './icons/earnings'
import Iban from './icons/iban'
import Vat from './icons/vat'
import { BANKING_CAMPAIGN_ID } from '../../../utils/constants'

const HeroWrapper = styled.section`
    /* Move the element up a notch to prevent white menu loading. */
    margin-top: -4rem;
    padding-top: 4rem;
    margin-bottom: 15rem;

    padding-bottom: 12rem;

    background-color: ${({ theme }) => theme.color.tellowPurple};

    position: relative;
    height: 100%;

    overflow-x: clip;

    &::after {
        content: '';
        position: absolute;
        left: -20%;
        bottom: -3rem;
        height: 7.5rem;
        width: 140%;
        border-radius: 50%/0 0 7.5rem 7.5rem;
        background-color: ${({ theme }) => theme.color.tellowPurple};
    }
`

const HeroLayout = styled.div`
    max-width: 80rem;
    padding-top: 5.5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 2rem;
    padding-right: 2rem;
    position: relative;
    z-index: 10;

    @media screen and (max-width: ${size.tablet}) {
        padding-top: 3.5rem;
    }
`

const HeroContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;

    z-index: 11;
`

const container = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            staggerChildren: 0.5,
        },
    },
}

const item = (opacity = 0) => ({
    hidden: { opacity, y: 15 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            ease: 'easeInOut',
            duration: 0.35,
            type: 'spring',
            damping: 30,
            mass: 1,
        },
    },
})

function template({ y }) {
    return `translateY(${y})`
}

const H1 = styled(motion.h1)`
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    align-items: flex-start;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 900;
    letter-spacing: -0.025em;
    line-height: 1.25;
    color: #eae7ff;
    white-space: pre-line;
    font-size: clamp(1.375rem, -0.375rem + 7.7778vw, 3.5rem);
    line-height: 1.15;
    font-weight: 600;
    max-width: 18ch;

    @media screen and (max-width: ${size.tablet}) {
        text-align: center;
    }
`

const Prose = styled(motion.p)`
    max-width: ${(props) => `var(--max-width, ${props.width})`};
    font-size: clamp(0.875rem, 0.625rem + 1.1111vw, 1.25rem);
    line-height: 1.35;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 300;
    text-align: left;
    color: #ffffff;
    opacity: 0.7;
    max-width: 51ch;

    @media screen and (max-width: ${size.tablet}) {
        text-align: center;
    }
`

const ButtonWrapper = styled(motion.div)`
    @media screen and (max-width: ${size.tablet}) {
        display: flex;
        justify-content: center;

        a {
            align-items: center;
        }
    }
`

const Text = () => (
    <motion.div variants={container} initial="hidden" animate="show">
        <H1 variants={item(0.5)} transformTemplate={template}>
            <span style={{ maxWidth: '18ch !important' }}>Bankieren en boekhouden in één, de beste combinatie voor ondernemers</span>
        </H1>
        <Prose variants={item()} transformTemplate={template}>
            Ideaal voor ondernemers. Run je onderneming snel, gemakkelijk en efficiënt met onze slimme technologie. Houd je boekhouding, zakelijke rekening en andere financiële
            zaken veilig in één app.
        </Prose>
        <ButtonWrapper variants={item()} transformTemplate={template}>
            <RegisterButton white big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Open gratis betaalrekening &nbsp;</span>
                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                    →
                </span>
            </RegisterButton>
        </ButtonWrapper>
    </motion.div>
)

const ImageWrapper = styled.span`
    position: absolute;
    right: 0;
    bottom: -14rem;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 1180px) {
        bottom: -25rem;
    }

    @media screen and (max-width: 350px) {
        bottom: -28rem;
    }

    > div {
        display: grid;
        grid-template:
            'b a'
            'b a'
            'c a'
            'd a'
            'd a'
            'd a'
            'd a';
        grid-template-columns: 1fr 1.75fr;

        width: 75%;
        float: right;

        @media screen and (max-width: 1180px) {
            > div.ibanWrapper,
            > div.earningsWrapper,
            > div.vatWrapper {
                display: none;
            }
        }

        @media screen and (max-width: ${size.tablet}) {
            grid-template: 'a';
            width: 100vw;

            > div.imageWrapper {
                justify-content: center !important;

                > img {
                    width: 80%;
                    max-width: 20rem;
                    height: unset !important;
                    float: unset !important;
                }
            }
        }

        > div.imageWrapper {
            grid-area: a;
            width: 100%;

            display: flex;
            justify-content: flex-end;

            > img {
                align-self: flex-start;
                height: 80%;
                margin-bottom: 0;
            }
        }

        > div.earningsWrapper {
            grid-area: b;
            position: relative;

            > svg {
                position: absolute;
                right: -7rem;
                bottom: -2rem;
                height: 3rem;
            }
        }

        > div.vatWrapper {
            grid-area: c;
            position: relative;

            > svg {
                position: absolute;
                right: -4rem;
                bottom: 0;

                height: 4rem;
            }
        }

        > div.ibanWrapper {
            grid-area: d;
            position: relative;

            > svg {
                position: absolute;
                right: -10rem;
                top: 2rem;

                height: 5rem;
            }
        }
    }
`

const LineWrapper = styled.span`
    position: absolute;
    left: 0;
    bottom: 5rem;
    min-height: 16rem;
    min-width: 100%;
    z-index: 1;
`

const Hero = React.forwardRef((_, ref) => (
    <HeroWrapper ref={ref}>
        <HeroLayout>
            <HeroContainer>
                <Text />
            </HeroContainer>
            <ImageWrapper>
                <div>
                    <div className="imageWrapper">
                        <img src={HeroImage} alt="Phone with Tellow bank card" />
                    </div>
                    <div className="earningsWrapper">
                        <Earnings />
                    </div>
                    <div className="vatWrapper">
                        <Vat />
                    </div>
                    <div className="ibanWrapper">
                        <Iban />
                    </div>
                </div>
            </ImageWrapper>
        </HeroLayout>
        <LineWrapper>
            <HeroLine />
        </LineWrapper>
    </HeroWrapper>
))

Hero.displayName = 'BankingHero'

export default Hero
