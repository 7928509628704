import React from 'react'

export default function Shield() {
    return (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="63.2394" height="63.2394" rx="31.6197" fill="#01FF94" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.345 22.5061C22.6203 22.4405 27.6925 20.4625 31.6195 16.9395C35.5463 20.4631 40.6185 22.4418 45.894 22.5079C46.0945 23.6923 46.1965 24.9131 46.1965 26.1539C46.1965 35.6746 40.1106 43.7739 31.6195 46.775C23.1284 43.7721 17.0425 35.6727 17.0425 26.1521C17.0425 24.9094 17.1463 23.6923 17.345 22.5061ZM38.3741 29.2625C38.706 28.9188 38.8897 28.4586 38.8855 27.9808C38.8814 27.5031 38.6898 27.046 38.3519 26.7082C38.0141 26.3704 37.5571 26.1787 37.0793 26.1746C36.6016 26.1704 36.1413 26.3541 35.7976 26.686L29.7974 32.6863L27.4414 30.3303C27.0977 29.9983 26.6374 29.8147 26.1597 29.8188C25.6819 29.823 25.2249 30.0146 24.8871 30.3525C24.5492 30.6903 24.3576 31.1473 24.3534 31.6251C24.3493 32.1028 24.533 32.5631 24.8649 32.9067L28.5091 36.551C28.8508 36.8926 29.3142 37.0845 29.7974 37.0845C30.2805 37.0845 30.7439 36.8926 31.0856 36.551L38.3741 29.2625Z"
                fill="#5950EC"
            />
        </svg>
    )
}
