import React from 'react'

export default function Phone() {
    return (
        <svg style={{ maxWidth: '7rem' }} viewBox="0 0 106 165" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M81.3912 158.507L21.9389 157.487C16.5784 157.395 12.3125 152.792 12.4073 147.207L14.6561 15.9614C14.7509 10.3756 19.1752 5.92017 24.5358 6.01201L81.8048 6.9934C89.6223 7.12041 91.34 11.5805 91.3388 17.0986L91.2732 148.382C91.4614 153.745 87.1875 158.462 81.3934 158.507L81.3912 158.507Z"
                fill="#5950EC"
            />
            <path
                d="M12.3215 147.206L14.5704 15.9604C14.6667 10.3383 19.1368 5.83753 24.5336 5.93087L81.8027 6.91228C90.3297 7.05174 91.4184 12.4305 91.4167 17.101L91.3516 148.38C91.4427 151.003 90.4448 153.603 88.6141 155.515C86.735 157.477 84.1673 158.57 81.3901 158.59L21.9356 157.57C21.658 157.566 21.3864 157.549 21.1137 157.52C16.1078 156.999 12.2303 152.539 12.3215 147.206ZM81.3867 158.425C84.1206 158.405 86.6431 157.331 88.4939 155.4C90.2938 153.522 91.2757 150.965 91.1849 148.383L91.2506 17.0996C91.2535 12.5045 90.1809 7.21165 81.7993 7.07596L24.5302 6.09456C19.2242 6.00386 14.8302 10.4305 14.7366 15.9617L12.4877 147.207C12.394 152.738 16.6332 157.313 21.9392 157.404L81.3915 158.424L81.3867 158.425Z"
                fill="#B3A5FF"
            />
            <path
                d="M80.213 156.328L21.7431 155.326C16.4712 155.237 12.2738 150.698 12.3696 145.19L14.5881 15.787C14.6817 10.2786 19.0334 5.88626 24.3028 5.97771L80.6258 6.94248C87.1907 7.45835 90.0036 11.4654 90.0014 16.9059L89.9298 146.346C89.927 151.923 85.5485 156.419 80.213 156.328Z"
                fill="#B3A5FF"
            />
            <path
                d="M24.4204 9.49177L80.835 10.3431C83.4396 10.3822 85.5311 12.5027 85.533 15.1071L85.4432 136.243L17.094 135.571L18.6716 15.069C18.7197 11.9413 21.295 9.44386 24.4229 9.48975L24.4204 9.49177Z"
                fill="#5950EC"
            />
            <path
                d="M56.8766 146.971C57.8691 144.148 56.3853 141.055 53.5624 140.063C50.7394 139.07 47.6463 140.554 46.6537 143.377C45.6611 146.2 47.1449 149.293 49.9679 150.286C52.7908 151.278 55.884 149.794 56.8766 146.971Z"
                fill="#5950EC"
            />
            <path
                d="M41.9235 16.2234C41.9235 16.2234 41.9325 16.2244 41.9393 16.2251L62.8355 16.5401C62.9266 16.5405 63.0024 16.4686 63.0028 16.3775C63.0032 16.2864 62.9313 16.2106 62.8403 16.2102L41.9441 15.8952C41.853 15.8948 41.7771 15.9667 41.7767 16.0578C41.7746 16.1441 41.8401 16.2147 41.9235 16.2234Z"
                fill="#B3A5FF"
            />
            <path
                d="M37.0045 18.7407C37.0045 18.7407 37.0136 18.7417 37.0203 18.7424L67.9551 19.2086C68.0462 19.209 68.1221 19.1372 68.1225 19.0461C68.1228 18.955 68.0508 18.8814 67.9599 18.8787L37.0251 18.4125C36.934 18.4121 36.8582 18.4839 36.8578 18.575C36.8556 18.6614 36.9212 18.732 37.0045 18.7407Z"
                fill="#B3A5FF"
            />
            <path
                d="M82.6881 81.1182C82.6804 82.7645 81.2099 84.1053 79.4199 84.097L23.8083 83.8508C22.0184 83.8426 20.5586 82.4889 20.5663 80.8426L20.7052 49.6646C20.7129 48.0183 22.1834 46.6776 23.9733 46.6858L79.585 46.9321C81.3749 46.9403 82.8346 48.294 82.8269 49.9403L82.6881 81.1182Z"
                fill="#01FF94"
            />
            <path
                d="M31.3082 62.9474C30.6875 62.3587 30.3325 61.6712 30.3336 61.0589C30.3359 60.4349 30.7055 59.7386 31.349 59.1479C31.6519 58.8684 31.9961 58.6328 32.367 58.4344C32.2913 58.4226 32.2139 58.4106 32.1355 58.4087L26.4049 58.3835C26.2853 58.3822 26.1691 58.3967 26.057 58.4203C26.4326 58.6277 26.7826 58.8649 27.086 59.1533C27.7067 59.742 28.0616 60.4295 28.0606 61.0417C28.0582 61.6658 27.6887 62.3621 27.0452 62.9528C26.5712 63.3878 25.9989 63.7213 25.3704 63.9597C25.6469 64.1818 25.9941 64.3212 26.3771 64.3225L32.1077 64.3477C32.4702 64.3485 32.8034 64.228 33.0752 64.0289C32.4033 63.7784 31.8022 63.4142 31.3082 62.9474Z"
                fill="#919BF5"
            />
            <path
                d="M27.7728 61.2291C27.7797 61.1666 27.8007 61.1005 27.8007 61.0389C27.8023 60.4986 27.4758 59.8792 26.9078 59.3391C26.5656 59.0156 26.1455 58.7621 25.6974 58.5449C25.152 58.8061 24.7711 59.3575 24.768 60.0037L24.7624 61.2141L27.773 61.2274L27.7728 61.2291Z"
                fill="#919BF5"
            />
            <path
                d="M24.7571 61.475L24.7514 62.6853C24.7503 63.0974 24.9059 63.4687 25.1563 63.7564C25.8062 63.5256 26.3955 63.1923 26.8675 62.7604C27.3017 62.3604 27.5972 61.9158 27.7249 61.4887L24.7571 61.475Z"
                fill="#919BF5"
            />
            <path
                d="M30.6628 61.5022C30.7839 61.9226 31.0689 62.3613 31.4885 62.7583C31.9808 63.225 32.6101 63.582 33.3087 63.8218C33.5767 63.5334 33.744 63.1499 33.7464 62.726L33.7521 61.5156L30.6645 61.5024L30.6628 61.5022Z"
                fill="#919BF5"
            />
            <path
                d="M33.7483 61.2562L33.7539 60.0458C33.7574 59.3654 33.343 58.7825 32.7528 58.5359C32.2931 58.7484 31.8736 59.015 31.5214 59.3386C30.9312 59.8805 30.5915 60.5083 30.5902 61.0606C30.5904 61.1205 30.6092 61.1825 30.6162 61.2431L33.7483 61.2562Z"
                fill="#919BF5"
            />
            <path
                d="M27.1962 69.2568C27.1935 69.761 27.1076 70.1347 26.9318 70.3786C26.7594 70.623 26.4895 70.7436 26.1253 70.7426C25.7646 70.7404 25.4974 70.6201 25.3238 70.3803C25.152 70.1406 25.0634 69.7819 25.0594 69.3077L25.0612 68.7349C25.0634 68.2357 25.1521 67.8675 25.3241 67.6265C25.4979 67.3857 25.7676 67.2668 26.1352 67.2681C26.4994 67.2691 26.7668 67.3876 26.9376 67.622C27.1085 67.8548 27.195 68.2167 27.1979 68.7011L27.1961 69.2585L27.1962 69.2568ZM26.7689 68.6673C26.7698 68.3031 26.7188 68.0374 26.6176 67.8706C26.5163 67.7037 26.3546 67.6191 26.134 67.6186C25.9134 67.6182 25.752 67.7012 25.6518 67.8646C25.5513 68.0314 25.4975 68.2854 25.4937 68.6288L25.4912 69.3161C25.4901 69.682 25.5407 69.951 25.6478 70.1271C25.7517 70.3011 25.9128 70.3907 26.1283 70.3906C26.3403 70.3919 26.4998 70.3103 26.6019 70.1454C26.7071 69.9826 26.7596 69.725 26.7679 69.3719L26.7706 68.6675L26.7689 68.6673Z"
                fill="white"
            />
            <path d="M29.1548 70.7084L28.7205 70.7065L28.7334 67.8395L27.8654 68.1557L27.8675 67.766L29.1017 67.309L29.17 67.3097L29.1548 70.7084Z" fill="white" />
            <path
                d="M32.6292 70.7241L30.4135 70.7149L30.4152 70.4055L31.5918 69.1092C31.7639 68.9144 31.886 68.7535 31.9524 68.6326C32.0192 68.5083 32.052 68.3819 32.0529 68.2503C32.0536 68.0742 31.9994 67.9296 31.8957 67.8155C31.7885 67.7028 31.6462 67.6442 31.4684 67.6434C31.2547 67.642 31.0873 67.7021 30.9694 67.8242C30.8501 67.9444 30.7903 68.114 30.7885 68.331L30.3593 68.3297C30.3614 68.0169 30.461 67.7662 30.6635 67.5748C30.8643 67.3833 31.1331 67.2881 31.4699 67.2895C31.7827 67.2916 32.0318 67.3739 32.2135 67.5411C32.3953 67.7066 32.4855 67.927 32.4843 68.2005C32.4833 68.5339 32.2685 68.9292 31.8414 69.3883L30.9308 70.3685L32.6283 70.3767L32.6272 70.7273L32.6292 70.7241Z"
                fill="white"
            />
            <path
                d="M33.7181 68.8281L34.0412 68.8296C34.2432 68.8281 34.4046 68.7758 34.5187 68.6721C34.6362 68.5688 34.6945 68.4282 34.6952 68.2521C34.6964 67.8554 34.5012 67.6559 34.1045 67.6547C33.9181 67.6546 33.7705 67.705 33.6594 67.8124C33.5487 67.9165 33.4921 68.0573 33.4914 68.2334L33.0606 68.2319C33.0612 67.9635 33.1627 67.7421 33.3584 67.5653C33.5558 67.3888 33.8051 67.3 34.1059 67.3025C34.4256 67.3037 34.6761 67.3896 34.8556 67.56C35.0369 67.7305 35.1256 67.9644 35.1231 68.2652C35.1222 68.4122 35.0757 68.5541 34.9783 68.6922C34.8828 68.8287 34.7533 68.9324 34.5885 68.9997C34.7735 69.0595 34.9183 69.1576 35.0178 69.2935C35.1189 69.4312 35.1706 69.5977 35.1694 69.7943C35.1682 70.0986 35.0664 70.3388 34.8688 70.5171C34.6678 70.6949 34.4102 70.781 34.0922 70.7801C33.7742 70.7791 33.5154 70.6906 33.3188 70.52C33.1191 70.3457 33.021 70.1193 33.0232 69.8373L33.4557 69.839C33.4548 70.0168 33.5126 70.16 33.6273 70.2668C33.7454 70.3739 33.9002 70.4287 34.095 70.4297C34.3019 70.4304 34.4617 70.3779 34.5731 70.2671C34.6824 70.1612 34.739 70.005 34.7393 69.8015C34.7405 69.6049 34.6802 69.4545 34.5603 69.3472C34.4401 69.2432 34.265 69.1879 34.0397 69.1835L33.7166 69.182L33.7179 68.8298L33.7181 68.8281Z"
                fill="white"
            />
            <path
                d="M39.9326 69.6189L40.4027 69.6213L40.4014 69.9735L39.9312 69.9711L39.9282 70.7558L39.4957 70.7541L39.4987 69.9694L37.9583 69.9631L37.9589 69.71L39.4852 67.3714L39.9415 67.374L39.9324 69.6205L39.9326 69.6189ZM38.447 69.6118L39.5 69.6172L39.5074 67.9589L39.456 68.0524L38.4468 69.6135L38.447 69.6118Z"
                fill="white"
            />
            <path
                d="M40.9908 69.0648L41.1707 67.3777L42.9043 67.3848L42.9031 67.7815L41.5354 67.7755L41.43 68.6978C41.5946 68.6014 41.7845 68.554 41.9948 68.555C42.3025 68.5566 42.5478 68.659 42.7286 68.8637C42.9097 69.0668 42.9988 69.3434 42.998 69.6906C42.9971 70.0394 42.9001 70.3126 42.7102 70.514C42.5222 70.7138 42.2581 70.8128 41.9196 70.8113C41.6203 70.8107 41.3764 70.7273 41.1897 70.5595C40.9995 70.3931 40.8935 70.1607 40.8691 69.8706L41.276 69.8729C41.299 70.067 41.3683 70.2132 41.4806 70.3112C41.5911 70.4106 41.7394 70.4613 41.9223 70.4627C42.1222 70.4643 42.2786 70.396 42.3945 70.2601C42.5088 70.1239 42.5656 69.9352 42.5681 69.696C42.5691 69.4704 42.5071 69.289 42.3856 69.1507C42.2622 69.0139 42.1007 68.943 41.8955 68.9425C41.7057 68.9421 41.5609 68.9825 41.451 69.0644L41.3382 69.1563L40.994 69.0669L40.9908 69.0648Z"
                fill="white"
            />
            <path
                d="M45.1604 67.3909L45.1592 67.7568L45.0823 67.7568C44.7483 67.7609 44.4791 67.8594 44.2817 68.0513C44.0809 68.2429 43.9655 68.5124 43.9338 68.8612C44.112 68.6569 44.3559 68.5556 44.6636 68.5571C44.9576 68.5589 45.1923 68.6635 45.3679 68.8694C45.5415 69.0785 45.6297 69.3482 45.6276 69.6764C45.6268 70.0235 45.531 70.302 45.3384 70.5116C45.148 70.718 44.8918 70.8231 44.5756 70.8206C44.2508 70.8188 43.9892 70.6941 43.7894 70.4445C43.5893 70.1966 43.4898 69.8743 43.4921 69.4828L43.4934 69.3171C43.4955 68.6947 43.632 68.2189 43.8973 67.8925C44.1647 67.5629 44.5609 67.3981 45.0868 67.3913L45.1621 67.3911L45.1604 67.3909ZM44.59 68.9133C44.443 68.9124 44.3063 68.9572 44.1837 69.0445C44.0578 69.1315 43.9719 69.2435 43.9231 69.3749L43.9228 69.5322C43.9211 69.8108 43.9833 70.0367 44.108 70.2078C44.2345 70.3792 44.3894 70.463 44.5775 70.4633C44.769 70.464 44.9237 70.3956 45.0336 70.2521C45.1466 70.1124 45.2017 69.9235 45.2033 69.6927C45.2051 69.4603 45.1471 69.2726 45.0365 69.1287C44.924 68.9862 44.7766 68.9117 44.5901 68.9116L44.59 68.9133Z"
                fill="white"
            />
            <path d="M48.3277 67.6524L46.9117 70.7883L46.4604 70.7862L47.8708 67.7626L46.0398 67.755L46.0413 67.4011L48.3288 67.4113L48.3277 67.6524Z" fill="white" />
            <path
                d="M53.2207 68.3146C53.2191 68.4837 53.175 68.6345 53.0853 68.7649C52.9955 68.8969 52.8731 68.9979 52.7212 69.0718C52.8974 69.1494 53.0373 69.2607 53.1391 69.4071C53.2426 69.5537 53.2926 69.72 53.2914 69.9012C53.2897 70.1952 53.1905 70.4271 52.9917 70.6001C52.793 70.773 52.5321 70.8588 52.209 70.8572C51.8842 70.8555 51.6235 70.7685 51.4293 70.5914C51.2331 70.4174 51.1374 70.1845 51.1373 69.892C51.1385 69.7108 51.1876 69.5452 51.2893 69.3991C51.3893 69.2528 51.5282 69.1416 51.7041 69.067C51.5533 68.9921 51.4365 68.8886 51.35 68.7576C51.2638 68.6249 51.2206 68.4747 51.222 68.3072C51.2228 68.0217 51.3162 67.796 51.4988 67.6297C51.6814 67.4635 51.9232 67.3808 52.2224 67.3814C52.5198 67.3835 52.762 67.4668 52.9435 67.6357C53.1268 67.803 53.2179 68.0303 53.2154 68.3157L53.2207 68.3146ZM52.8634 69.8897C52.8636 69.7016 52.8039 69.5462 52.6837 69.4268C52.5652 69.3077 52.4078 69.2458 52.2146 69.2449C52.0197 69.2439 51.8643 69.3036 51.7472 69.419C51.6297 69.5377 51.5716 69.692 51.5693 69.8833C51.5684 70.0765 51.6235 70.228 51.7379 70.3382C51.8506 70.4481 52.0085 70.5049 52.2118 70.5069C52.4136 70.507 52.5719 70.4527 52.6867 70.3422C52.8014 70.2335 52.8608 70.0827 52.8615 69.8912L52.8634 69.8897ZM52.2245 67.734C52.0553 67.7324 51.9194 67.7857 51.8139 67.8887C51.7083 67.9933 51.6548 68.1362 51.654 68.314C51.6537 68.4867 51.705 68.6258 51.8077 68.7329C51.9107 68.8384 52.0485 68.8913 52.2193 68.8931C52.3921 68.8934 52.5295 68.8419 52.6334 68.7371C52.7359 68.6304 52.7871 68.4924 52.7891 68.3199C52.7892 68.1488 52.7381 68.008 52.6321 67.8988C52.5262 67.7896 52.3902 67.7352 52.2261 67.7342L52.2245 67.734Z"
                fill="white"
            />
            <path
                d="M55.4845 69.3385C55.3941 69.4448 55.2871 69.5304 55.1604 69.5934C55.0369 69.6584 54.8982 69.6909 54.7493 69.6916C54.5544 69.6905 54.3855 69.641 54.2407 69.5429C54.0972 69.4483 53.9858 69.3112 53.9059 69.1364C53.8296 68.9603 53.7892 68.7694 53.7904 68.5575C53.7914 68.3318 53.8364 68.1264 53.9212 67.9476C54.008 67.7657 54.1311 67.6271 54.2905 67.5318C54.4481 67.4364 54.6312 67.3899 54.8433 67.3894C55.1784 67.3906 55.4432 67.5174 55.6327 67.7676C55.8255 68.0198 55.9195 68.3603 55.9178 68.7929L55.9177 68.9177C55.915 69.576 55.7832 70.0557 55.5205 70.3584C55.2597 70.6597 54.8645 70.8144 54.3405 70.8197L54.2568 70.819L54.258 70.4531L54.3486 70.4528C54.7033 70.4476 54.9768 70.3564 55.1674 70.1791C55.3581 70.0018 55.4629 69.7192 55.4817 69.333L55.4845 69.3385ZM54.8229 69.3354C54.9649 69.3357 55.0995 69.2941 55.2224 69.2034C55.3431 69.1176 55.4322 69.0077 55.4894 68.8772L55.4897 68.7045C55.4901 68.4223 55.4317 68.1935 55.3094 68.0157C55.1871 67.838 55.0327 67.7491 54.8463 67.749C54.6565 67.7485 54.5048 67.8206 54.3898 67.9636C54.2746 68.1082 54.2159 68.2984 54.2153 68.5361C54.2137 68.7668 54.2695 68.9577 54.3779 69.1065C54.4895 69.2574 54.6369 69.3319 54.8231 69.3337L54.8229 69.3354Z"
                fill="white"
            />
            <path d="M57.9165 70.8354L57.4823 70.8335L57.4951 67.9665L56.6271 68.2827L56.6292 67.8929L57.8634 67.436L57.9317 67.4367L57.9165 70.8354Z" fill="white" />
            <path
                d="M61.3046 69.4072C61.3019 69.9114 61.216 70.2851 61.0402 70.529C60.8678 70.7734 60.5979 70.894 60.2337 70.893C59.873 70.8908 59.6058 70.7705 59.4322 70.5307C59.2603 70.291 59.1718 69.9323 59.1678 69.458L59.1696 68.8853C59.1718 68.386 59.2605 68.0178 59.4325 67.7769C59.6063 67.5361 59.876 67.4172 60.2436 67.4185C60.6078 67.4195 60.8752 67.538 61.046 67.7724C61.2169 68.0052 61.3034 68.3671 61.3063 68.8514L61.3045 69.4089L61.3046 69.4072ZM60.879 68.8178C60.8799 68.4536 60.8289 68.188 60.7277 68.0211C60.6264 67.8543 60.4647 67.7697 60.2441 67.7692C60.0235 67.7688 59.8621 67.8518 59.7619 68.0152C59.6597 68.1818 59.6076 68.436 59.6038 68.7794L59.6013 69.4667C59.6002 69.8326 59.6508 70.1016 59.7579 70.2776C59.8618 70.4516 60.0229 70.5413 60.2384 70.5412C60.4504 70.5424 60.6099 70.4609 60.712 70.296C60.8172 70.1332 60.8697 69.8756 60.878 69.5224L60.8807 68.818L60.879 68.8178Z"
                fill="white"
            />
            <path d="M65.6177 70.8695L65.1834 70.8677L65.1963 68.0007L64.3283 68.3168L64.3304 67.9271L65.5646 67.4701L65.6329 67.4709L65.6177 70.8695Z" fill="white" />
            <rect x="37.0005" y="118" width="30" height="9" rx="4.5" fill="#7A73F2" />
            <circle cx="53.0005" cy="105" r="7" fill="#01FF94" fillOpacity="0.71" />
            <path
                d="M53.0013 101.054C51.9551 101.055 50.9521 101.471 50.2123 102.211C49.4725 102.951 49.0564 103.954 49.0552 105C49.272 110.235 56.7315 110.233 56.9475 105C56.9463 103.954 56.5302 102.951 55.7904 102.211C55.0506 101.471 54.0475 101.055 53.0013 101.054ZM53.0013 108.531C52.0652 108.53 51.1678 108.157 50.5059 107.495C49.844 106.833 49.4716 105.936 49.4706 105C49.6645 100.316 56.3388 100.317 56.5321 105C56.531 105.936 56.1587 106.833 55.4968 107.495C54.8349 108.157 53.9374 108.53 53.0013 108.531Z"
                fill="white"
            />
            <path
                d="M54.5165 103.561L52.6326 105.445L51.764 104.577C51.7249 104.539 51.6724 104.518 51.6178 104.518C51.5632 104.519 51.511 104.54 51.4724 104.579C51.4339 104.618 51.412 104.67 51.4116 104.724C51.4112 104.779 51.4323 104.831 51.4703 104.871L52.4857 105.886C52.5248 105.925 52.5776 105.946 52.6326 105.946C52.6876 105.946 52.7403 105.925 52.7794 105.886L54.8102 103.855C54.8481 103.816 54.8691 103.763 54.8687 103.709C54.8682 103.654 54.8464 103.602 54.8078 103.564C54.7693 103.525 54.7171 103.503 54.6626 103.503C54.6081 103.503 54.5556 103.524 54.5165 103.561Z"
                fill="white"
            />
        </svg>
    )
}
