import React from 'react'
import tw from 'twin.macro'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer } from '../../components/shared/landingPages/layoutComponents'
// import BankingHeader from '../../components/pages/banking/banking-header'
// import BankingFeatures from '../../components/pages/banking/banking-features'
import BankingAccountancy from '../../components/pages/banking/banking-accountancy'
// import TellowLogo from '../../images/banking/Frame.png'
// import DocumentIcon from '../../images/banking/document.png'
import contactlessIconIcon from '../../images/banking/contactlessIcon.png'
// import ShieldLockIcon from '../../images/banking/shieldLock.png'
// import EarthIcon from '../../images/banking/earth.png'
// import CreditCardIcon from '../../images/banking/creditCard.png'
// import LottieBankingAnimation from '../../images/lottieAnimations/BankingAnimation.js'
import Hero from '../../components/pages/banking/hero'
import * as Banking from '../../components/pages/banking/styled'
import Chat from '../../components/pages/banking/icons/chat'
import Globe from '../../components/pages/banking/icons/globe'
import Minutes from '../../components/pages/banking/icons/minutes'
import Notification from '../../components/pages/banking/icons/notification'
import Phone from '../../components/pages/banking/icons/phone'
import Phones from '../../components/pages/banking/icons/phones'
import { Accordeon, RegisterButton } from '../../components/UI'
import CircleWithCard from '../../images/banking/circle-with-card.png'

import iPhoneSuccess from '../../images/banking/iphone-success.png'

import CardWithImage from '../../images/banking/card-with-image.png'
import CardWithCategory from '../../images/banking/card-with-category.png'
import CardBackAndFront from '../../images/banking/card-back-and-front.png'

import Lock from '../../components/pages/banking/icons/lock'
import Fingerprint from '../../components/pages/banking/icons/fingerprint'
import Shield from '../../components/pages/banking/icons/shield'

import LottieBankingAnimation from '../../images/lottieAnimations/BankingAnimation.js'

import StepsSvg from '../../components/pages/banking/icons/steps'
import { BANKING_CAMPAIGN_ID } from '../../utils/constants.js'

// const bankingHeaderData = {
//     titleText: `Nieuw voor zzp’ers`,
//     title: ` Tellow Bankieren`,
//     titleImage: TellowLogo,
//     text: `Onze alles-in-een oplossing die meer doet dan je bank, allemaal vanuit één app.`,
//     list: [`IBAN en Mastercard-betaalkaart`, `Facturen en offertes maken`, `Bonnen en administratie bijhouden`, `Belasting indienen en betalen`],
//     fadedText: `In 2022 krijgen alle zzp'er de zakelijke bankrekening gratis, voor altijd!`,
//     animation: <LottieBankingAnimation />,
// }

// const featuresData = [
//     {
//         Icon: CreditCardIcon,
//         title: `Alles wat je nodig hebt`,
//         text: `Eigen IBAN, realtime transfers naar alle Europese IBANs en ondersteuning SEPA-incasso’s`,
//     },
//     {
//         Icon: DocumentIcon,
//         title: `Automatisch boekhouden`,
//         text: `Makkelijker boekhouden plus belastingaangiftes direct indienen én betalen`,
//     },
//     {
//         Icon: EarthIcon,
//         title: `Overal betalen`,
//         text: `Met Mastercard, ApplePay en GooglePay hebben we sterke dekking in heel Nederland`,
//     },
//     {
//         Icon: ShieldLockIcon,
//         title: `Veilig en zekerheid`,
//         text: `Verzekerd van je geld met 100% garantie op je saldo, ook boven de 100.000 euro`,
//     },
// ]

const bankingAccountancyData = {
    title: `Één app voor boekhouding`,
    text: `Wij geloven in een one-stop-shop voor ondernemers, waar we alle financiële zaken voor ondernemers regelen vanuit een plek. Administratie, boekhouden, bankieren, belastingen, financiering en meer, van het starten van je onderneming tot het beëindigen. Wij maken het makkelijker en dat scheelt jou kostbare tijd!`,
    points: [
        'Gratis Nederlandse bankrekening',
        'Automatisch boekhouden',
        'Overal makkelijk bankieren',
        'Makkelijk factureren',
        'Bonnen scannen en digitaal bewaren',
        'Uitgaven automatisch verwerken',
        'En nog veel meer',
    ],
    cardNumber: `1234 5678 9012 3456`,
    cardName: `CARD HOLDER`,
    icon: contactlessIconIcon,
    campaign: BANKING_CAMPAIGN_ID,
}

const UserReviewsData = {
    title: `Nog meer ondernemers die </br> Tellow ontzorgd`,
    testimonialCard: [
        {
            review: `Het systeem kan mijn inkoopfacturen in PDF-formaat automatisch lezen en de gegevens voor mij invullen. Zo zorgt Tellow ervoor dat mijn boekhouding klopt op basis van facturen en bankgegevens.`,
            name: `Maria`,
            position: `Yoga instructeur`,
            stars: 5,
        },
        {
            review: `Zelf eerst het starterspakket genomen. Het beviel zo goed dat ik het beste pakket koos en toen werd het pas echt super! De service is top, zowel bellen als mailen, en je wordt direct geholpen met het juiste advies!`,
            name: `Jopie`,
            position: `Glazenwasser`,
            stars: 5,
        },
        {
            review: `Tot nu toe zeer tevreden met Tellow. Het programma zelf is erg intuïtief en ik kwam er zelf snel uit. Aanrader voor elke startende ondernemer!`,
            name: `Tom`,
            stars: 5,
            position: `IT professional`,
        },
        {
            review: `Ik had door mijn bank een probleem met mijn bankkoppeling wat moeilijk op te lossen was. Maron van het Tellow Support team heeft er bovenop gezeten tot het probleem verholpen was.`,
            name: `Hanna`,
            stars: 5,
            position: `Zorgmedewerker`,
        },
        {
            review: `Wat een top service! Om 21:00 uur nog een chatbericht gestuurd en kreeg direct antwoord (wat ik niet had verwacht); een paar minuutjes later was mijn probleem volledig opgelost.`,
            name: `Jose`,
            stars: 5,
            position: `Teamleider sales`,
        },
        {
            review: `Vragen aan Tellow medewerkers worden zowel per mail als via de app snel beantwoord en als het dan nog niet duidelijk is is telefonische assistentie beschikbaar. En dat alles tegen een heel redelijk tarief.`,
            name: `Jan`,
            stars: 4.5,
            position: `Commercieel adviseur`,
        },
        {
            review: `Fijn dat Remco de missende bankposten snel kon inlezen. Hij heeft aantal missende buitenlandse overschrijvingen apart voor me ingelezen, zodat ik mijn facturen dezelfde dag kon boeken.`,
            name: `Marco`,
            stars: 4.5,
            position: `Data analyst`,
        },
        {
            review: `Tellow is perfect voor de kleine ondernemer. Een klantvriendelijk pakket met veel mogelijkheden. Prima support bij vragen. Reageren echt snel en adequaat. Fijn om mee te werken.`,
            name: `André `,
            stars: 5,
            position: `Productontwikkelaar`,
        },
        {
            review: `Ik kan niet omschrijven hoe veel moeite Tellow mij bespaart met mijn boekhouden. Een user-friendly design, speciale features voor Nederlandse bedrijven en top klantenservice. Zeker een aanrader.`,
            name: `Adam`,
            stars: 5,
            position: `Business developer`,
        },
    ],
}

const FAQ = [
    {
        question: 'Wat kost bankieren met Tellow?',
        answer: `
            Een groot deel van de services die wij aanbieden zijn gratis. De overige kosten vind je <a href="https://tellow.nl/prijzen/20240813_Overzicht_prijzen_NL.pdf" target="_blank">hier</a>.
        `,
    },
    {
        question: 'Hoe lang duurt het voordat de verificatie van identiteit is voltooid?',
        answer: `
            In de meeste gevallen is de verificatie binnen 10 minuten afgerond. In enkele gevallen kan het iets langer duren omdat er bijvoorbeeld nog meer informatie benodigd is. Je ontvangt dan vanzelf een email van onze partner Swan.
        `,
    },
    {
        question: 'Wat is Swan?',
        answer: `
            Tellow werkt samen met de Franse bank Swan, een Europese Banking-as-a-Service provider met een vergunning bij de ACPR (Bank of France). Het bedrijf is in 2019 opgericht en snel opgeschaald naar negen landen met een transactievolume van 2,5 miljard op jaarbasis. Meer informatie over <a href="https://www.swan.io/about" target="_blank">Swan</a>.
        `,
    },
    {
        question: 'Is mijn geld veilig?',
        answer: `
            Je saldo wordt vastgehouden door een grote Europese bank en 100% gegarandeerd, ook bij bedragen boven 100.000 euro.
        `,
    },
    {
        question: 'Hoe veilig is bankieren met Tellow?',
        answer: `
            Onze bankpartner Swan hanteert een strenge en grondige beveiliging, die volgens de laatste standaarden en eisen die aan banken gesteld worden. Jouw online bankomgeving is beveiligd met modernste technieken voor cyberveiligheid, multi-level authenticatie en biometrisch inloggen.
        `,
    },
    {
        question: 'Waar kan ik allemaal betalen met mijn Tellow kaart?',
        answer: `
            Je Tellow kaart maakt gebruik van het Mastercard netwerk en je kunt de kaart overal gebruiken waar Mastercard geaccepteerd wordt.
        `,
    },
    {
        question: 'Hoelang duurt het voordat mijn transactie of overboeking verwekt is?',
        answer: `
            Alle transacties en overboekingen binnen de Eurozone zijn realtime.
        `,
    },
    {
        question: 'Ik heb een email van Swan gekregen, wat nu?',
        answer: `
            Het kan zijn dat onze bankpartner Swan je een email stuurt tijdens het aanmeld- of verificatieproces. Het kan soms voorkomen dat ze meer informatie of documenten nodig hebben om het proces af te ronden.
        `,
    },
    {
        question: 'Welke soorten bedrijven worden niet ondersteund met Tellow Bankieren?',
        answer: `
            Hoewel de meeste soorten bedrijven een Tellow bankrekening kunnen openen, mogen sommige dit niet. Deze beslissing heeft niets te maken met of we een bepaalde industrie "leuk vinden" of "niet leuk vinden". Op dit moment zijn we echter niet ingesteld om bedrijven te ondersteunen die een risico vormen voor Tellow of haar partners.
            <br/><br/>
            Bovendien moeten we voldoen aan de eisen van de betalingsnetwerken en instellingen die ons helpen draaien, evenals de wetten van elk land waarin we actief zijn.
            <br/><br/>
            Voorbeelden van industrieën waarmee we niet kunnen werken:
            <ul>
                <li>Verkoop van wapens en munitie</li>
                <li>Pornografie</li>
                <li>Verkoop van verdovende middelen, illegale drugs, synthetische drugs en marihuana</li>
            </ul>
        `,
    },
    {
        question: 'Ik heb een vraag over mijn Tellow bankrekening, waar moet ik zijn?',
        answer: `
            Voor al je vragen kun je gewoon terecht bij het support team van Tellow. We zijn voor iedereen elke werkdag tussen 09:00 en 21:00 uur bereikbaar via email of chat en voor Plus en Compleet gebruikers ook via telefoon.
        `,
    },
]

const ComingSoon = () => (
    <Layout registrationButtonCampaignId={BANKING_CAMPAIGN_ID}>
        <Meta
            keywords={['boekhouder', 'zzp', 'freelancer', 'ondernemer', 'eenmanszaak', 'administratie', 'boekhouden', 'boekhoudpakket']}
            path="/functies/zakelijke-rekening-openen"
            title="Bankieren voor ondernemers | Tellow"
            description="Meta description: Bankieren voor ondernemers. Met Tellow bankieren krijgen ondernemers en zzp’ers toegang tot één app waarin zij all hun financien kunnen regelen."
        />

        <Hero />

        <Banking.RelativeContainer>
            <Banking.ComponentContainer>
                <Banking.Stats>
                    <div>
                        <h1>Makkelijk aanvragen</h1>
                        <p>Rekening openen in minuten</p>
                    </div>
                    <div>
                        <h1>Snelle verwerking</h1>
                        <p>In drie uur een lokale IBAN</p>
                    </div>
                    <div>
                        <h1>Direct betalen</h1>
                        <p>Virtuele pas werkt direct</p>
                    </div>
                </Banking.Stats>
            </Banking.ComponentContainer>
        </Banking.RelativeContainer>

        <Banking.ColouredBackground purple>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer padding={3} gap={3} margin={0} direction="column">
                    <Banking.H1>Voor €0 een Nederlandse zakelijke rekening openen!</Banking.H1>
                    <Banking.IbanFeaturesGrid>
                        <Banking.IbanFeature>
                            <Globe />
                            <p className="title">Wereldwijd betalen met je betaalkaart, 100% dekking in Nederland</p>
                        </Banking.IbanFeature>
                        <Banking.IbanFeature>
                            <Phone />
                            <p className="text">Met ApplePay en GooglePay betaal je veilig met je telefoon</p>
                        </Banking.IbanFeature>
                        <Banking.IbanFeature>
                            <Chat />
                            <p className="text">Van 09:00 to 21:00 zijn we bereikbaar via mail, chat en een directe telefoonlijn</p>
                        </Banking.IbanFeature>
                        <Banking.IbanFeature>
                            <Notification />
                            <p className="title">Realtime Meldingen</p>
                            <p className="text">Betaald? Ontvang automatisch een notificatie om je bon te fotograferen. Wij doen de boekhouding.</p>
                        </Banking.IbanFeature>
                        <Banking.IbanFeature>
                            <Phones />
                            <p className="text">Realtime transfers naar alle Europese IBANs en ondersteuning SEPA-incasso's</p>
                        </Banking.IbanFeature>
                        <Banking.IbanFeature>
                            <Minutes />
                            <p className="title">Account openning</p>
                            <p className="text">Open je zakelijke rekening binnen een paar minuten</p>
                        </Banking.IbanFeature>
                    </Banking.IbanFeaturesGrid>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <Banking.ColouredBackground purple>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} justify="flex-end">
                    <Banking.OpenFreeAccountWrapper>
                        <Banking.H2>Ontdek onze gratis zakelijke betaalrekening</Banking.H2>
                        <p>
                            Nooit meer onnodige kosten! Tellow biedt een gratis zakelijke rekening zonder vaste kosten. Begin zonder zorgen met de goedkoopste zakelijke rekening
                            van Nederland.
                        </p>
                        <RegisterButton white big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                            <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Open gratis betaalrekening &nbsp;</span>
                            <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                →
                            </span>
                        </RegisterButton>
                    </Banking.OpenFreeAccountWrapper>
                </Banking.ComponentContainer>
                <Banking.CircleCardWrapper>
                    <img src={CircleWithCard} alt="Tellow Card with expense category" />
                </Banking.CircleCardWrapper>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <Banking.ColouredBackground purpleLight>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125}>
                    <Banking.TwoPartContentBlock>
                        <div className="custom-padding">
                            <Banking.H2 purple thick>
                                Open je zakelijke betaalrekening binnen een paar minuten
                            </Banking.H2>
                            <Banking.Steps>
                                <StepsSvg />
                                <p style={{ gridArea: 'b', paddingBottom: '0.5rem' }}>Meld je aan</p>
                                <p style={{ gridArea: 'c', paddingBottom: '0.25rem' }}>Doorloop de snelle verificatie</p>
                                <p style={{ gridArea: 'd' }}>Ontvang je gratis virtuele kaart</p>
                            </Banking.Steps>
                            <RegisterButton purple big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Direct aanmelden &nbsp;</span>
                                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                    →
                                </span>
                            </RegisterButton>
                        </div>
                        <span className="custom-positioning">
                            <img src={iPhoneSuccess} alt="Tellow Card" />
                        </span>
                    </Banking.TwoPartContentBlock>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <Banking.ColouredBackground purple>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} padding={6}>
                    <Banking.TwoPartContentBlock>
                        <span className="left-align">
                            <img src={CardWithImage} alt="Tellow Card" />
                        </span>
                        <div className="with-padding">
                            <Banking.H2 thick style={{ maxWidth: '15ch' }}>
                                Binnen 3 Uur een Nederlandse IBAN
                            </Banking.H2>
                            <p className="white">Wij hebben bankieren nog sneller gemaakt. Met Tellow kun je tijdens kantooruren in 3 uur je lokale IBAN ontvangen.</p>
                            <RegisterButton white big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Makkelijk aanvragen &nbsp;</span>
                                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                    →
                                </span>
                            </RegisterButton>
                        </div>
                    </Banking.TwoPartContentBlock>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <Banking.ColouredBackground purpleLight>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} padding={6}>
                    <Banking.TwoPartContentBlock>
                        <div>
                            <Banking.H2 purple thick>
                                Slimme rekening met geautomatiseerd btw-beheer
                            </Banking.H2>
                            <p className="purple">
                                Zeg vaarwel tegen handmatig kosten categoriseren. Met Tellow neemt ons systeem het werk van je over. Betalingen met de Tellow Mastercard worden
                                direct gecategoriseerd, waardoor de administratieve last wordt verlicht. Zo kun jij je volledig concentreren op wat er echt toe doet: de groei van
                                je bedrijf.
                            </p>
                            <RegisterButton purple big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Slimme rekening openen &nbsp;</span>
                                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                    →
                                </span>
                            </RegisterButton>
                        </div>
                        <span>
                            <img src={CardWithCategory} alt="Tellow Card" />
                        </span>
                    </Banking.TwoPartContentBlock>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <Banking.ColouredBackground white>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} padding={6}>
                    <Banking.TwoPartContentBlock>
                        <span className="left-align">
                            <img src={CardBackAndFront} alt="Tellow Card back and front" />
                        </span>
                        <div className="with-padding">
                            <Banking.H2 purple thick>
                                Fysieke pinpas van <br />
                                <span>gerecycled plastic</span>
                            </Banking.H2>
                            <p>Een duurzame keuze voor de bewuste consument. Help mee aan een groenere toekomst met elke aankoop.</p>
                            <RegisterButton purple big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Duurzame rekening openen &nbsp;</span>
                                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                    →
                                </span>
                            </RegisterButton>
                        </div>
                    </Banking.TwoPartContentBlock>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <Banking.ColouredBackground purpleLight>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} padding={6}>
                    <Banking.TwoPartContentBlock>
                        <div>
                            <Banking.H2 purple thick>
                                Maak belastingaangifte makkelijker
                            </Banking.H2>
                            <p className="purple">
                                Maak het belastingseizoen eenvoudiger. Met Tellow stroomlijn je belastingprocessen en betaal je zelfs je belastingaangifte direct vanuit je
                                zakelijke bankrekening. Vaarwel belastingzorgen!
                            </p>
                            <RegisterButton purple big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                                <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Gratis uitproberen &nbsp;</span>
                                <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                    →
                                </span>
                            </RegisterButton>
                        </div>
                        <span className="with-animation">
                            <LottieBankingAnimation />
                        </span>
                    </Banking.TwoPartContentBlock>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <Banking.ColouredBackground purple>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} padding={6}>
                    <Banking.SecurityWrapper>
                        <Banking.SecurityBlock>
                            <Lock />
                            <h3>Mastercard 3D Secure</h3>
                            <p>Jouw veiligheid staat bij ons voorop. Tellow beschikt over Mastercard 3D Secure, waarmee je extra bescherming krijgt voor je transacties.</p>
                        </Banking.SecurityBlock>
                        <Banking.SecurityBlock>
                            <Fingerprint />
                            <h3>Biometrische Identificatie</h3>
                            <p>
                                Ervaar veilige en gemakkelijke authenticatie met biometrische identificatie methoden, zodat je eenvoudig en veilig toegang krijgt tot je Tellow
                                account.
                            </p>
                        </Banking.SecurityBlock>
                        <Banking.SecurityBlock>
                            <Shield />
                            <h3>Bescherming gelden</h3>
                            <p>
                                Wij gaan verder dan de depositogarantie van de Nederlandse overheid. Door onze samenwerking met BNP Paribas zijn alle gelden door de grootste bank
                                van Frankrijk gegarandeerd, ook boven de 100.000 euro.
                            </p>
                        </Banking.SecurityBlock>
                    </Banking.SecurityWrapper>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        {/* banking accountancy componenet */}
        {/* text transition library is used for animation */}
        <RelativeContainer>
            <ComponentContainer margin={6}>
                <BankingAccountancy data={bankingAccountancyData} />
            </ComponentContainer>
        </RelativeContainer>

        {/* <UserReviews data={UserReviewsData} /> */}

        <Banking.ColouredBackground purple>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={0} padding={6}>
                    <Banking.CTAWrapper>
                        <Banking.H2>Bekijk Tellow in Actie!</Banking.H2>
                        <p>
                            Bekijk hoe Tellow jouw financiële wereld kan transformeren. Leer meer over onze gebruiksvriendelijke oplossingen voor bankieren, boekhouden, belastingen
                            en nog veel meer. Klaar om te ontdekken hoe Tellow jou kan helpen groeien?
                        </p>
                        <iframe
                            style={{ width: '100%', aspectRatio: '560/315' }}
                            src="https://www.youtube.com/embed/U-bJNBkv05M?si=zZHUK6rV3JP49Nsu"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        />
                    </Banking.CTAWrapper>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>

        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <p css={tw`text-xl md:text-2xl leading-tight tracking-tight font-medium text-tellow-purple items-start mb-2`}>Veelgestelde vragen</p>
                {FAQ.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>

        <Banking.ColouredBackground purple>
            <Banking.RelativeContainer>
                <Banking.ComponentContainer margin={-0.125} padding={6}>
                    <Banking.CTAWrapper>
                        <Banking.H2>Ontdek de Eenvoud van Tellow Banking!</Banking.H2>
                        <p style={{ maxWidth: '44ch', fontSize: 'clamp(1rem, -0.375rem + 5.7778vw, 1.5rem)', fontWeight: 300 }}>
                            Alles wat je nodig hebt om je boekhouding en financiën eenvoudig onder controle te houden.
                        </p>
                        <RegisterButton agerasGreen big campaign={BANKING_CAMPAIGN_ID} style={{ padding: '0.5rem 2rem', alignItems: 'center' }}>
                            <span style={{ fontWeight: 600, fontSize: 'clamp(0.875rem, 0.625rem + 1.1111vw, 0.9rem)' }}>Open nu je account &nbsp;</span>
                            <span style={{ fontWeight: 600, fontSize: '1rem' }} arrow="true">
                                →
                            </span>
                        </RegisterButton>
                    </Banking.CTAWrapper>
                </Banking.ComponentContainer>
            </Banking.RelativeContainer>
        </Banking.ColouredBackground>
    </Layout>
)

export default ComingSoon
