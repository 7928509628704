import React from 'react'

export default function HeroLine() {
    return (
        <svg style={{ height: 'inherit' }} viewBox="0 0 1440 265" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 205.726C12.4043 202.474 43.5106 201.39 68.701 223.072C100.189 250.174 154.68 250.656 166.027 231.745C180.34 207.893 174.615 130.922 217.553 133.09C240.424 134.245 246.179 160.192 246.179 181.874C246.179 203.556 260.112 231.745 303.429 231.745C340.642 231.745 349.23 208.977 360.68 189.463C372.13 169.949 409.343 172.117 409.343 195.968C409.343 225.157 409.343 261.013 455.144 261.013C535.499 261.013 515.257 124.42 532.433 23.5986C537.419 -5.67144 606.859 -8.92305 609.721 62.6273C612.892 141.876 626.897 181.872 629.759 189.463C632.622 197.054 646.934 215.484 689.872 194.886C724.223 178.408 751.057 185.127 758.573 194.886C778.611 220.902 790.634 247.788 827.274 246.921C875.938 245.769 878.8 141.763 907.426 73.4646C914.695 56.1195 958.951 56.1195 958.951 84.3061C958.951 113.797 953.226 155.856 987.577 167.782C1005.73 174.083 1028.37 172.118 1039.1 155.856C1045.97 145.449 1053.42 73.4646 1064.87 36.6053C1076.32 9.50334 1119.25 14.9233 1130.7 36.6053C1146.73 66.9605 1179.59 184.617 1250.93 155.856C1339.67 120.081 1384.46 147.232 1402.65 115.744C1422.68 81.0538 1428.41 56.1195 1517.15 73.4646C1605.01 90.6379 1657.41 77.4396 1691.76 73.4646"
                stroke="url(#paint0_linear_1052_10379)"
                strokeWidth="7.06094"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <defs>
                <linearGradient id="paint0_linear_1052_10379" x1="1789.73" y1="-118.277" x2="-36.3163" y2="402.244" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5950EC" />
                    <stop offset="1" stopColor="#4318FF" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    )
}
