import React from 'react'
import styled from 'styled-components'

const StyledMask = styled.mask`
    mask-type: alpha;
`

export default function Steps() {
    return (
        <svg height="243" viewBox="0 0 49 208" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path stroke="#5950EC" strokeWidth="1.08" strokeLinecap="round" strokeDasharray="3.24 6.49" d="M23.54 11v190.92" />
            <circle cx="24.5" cy="24.33" r="23.94" stroke="#5950EC" strokeWidth=".78" />
            <circle cx="24.5" cy="24.33" r="23.33" fill="#fff" stroke="#5950EC" strokeWidth="2" />
            <circle cx="24.33" cy="104.79" r="23.33" fill="#fff" stroke="#5950EC" strokeWidth="2" />
            <circle cx="24.33" cy="182.79" r="23.33" fill="#fff" stroke="#5950EC" strokeWidth="2" />
            <StyledMask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="49" height="49">
                <circle cx="24.5" cy="24.33" r="23.33" stroke="#5950EC" strokeWidth="2" />
            </StyledMask>
            <g mask="url(#a)">
                <path d="m-18.22 23.8 43.8-34.61V22.7l31.36-1.08-31.36 42.72-43.8-40.56Z" fill="#FFFFFF" />
            </g>
            <StyledMask id="b" maskUnits="userSpaceOnUse" x="0" y="0" width="49" height="49">
                <circle cx="24.5" cy="24.33" r="23.33" stroke="#5950EC" strokeWidth="2" />
            </StyledMask>
            <g mask="url(#b)">
                <path d="m-18.22 23.8 43.8-34.61V22.7l31.36-1.08-31.36 42.72-43.8-40.56Z" fill="#FFFFFF" />
            </g>
            <StyledMask id="c" maskUnits="userSpaceOnUse" x="0" y="80" width="49" height="50">
                <circle cx="24.33" cy="104.79" r="23.33" stroke="#5950EC" strokeWidth="2" />
            </StyledMask>
            <g mask="url(#c)">
                <path d="m-28.5 97.46 53-23-1 57 3.54 3.61-37.04 4.39-18.5-42Z" fill="#FFFFFF" />
            </g>
            <path
                d="M26.95 17.77v12.59h-2.28V19.99h-.08l-2.94 1.88v-2.09l3.13-2h2.17ZM19.5 110.27v-1.64l4.37-4.29c.42-.42.77-.8 1.05-1.12.27-.33.48-.65.62-.95.14-.31.21-.64.21-.99a1.8 1.8 0 0 0-1.01-1.68 2.4 2.4 0 0 0-1.08-.23c-.41 0-.77.08-1.08.25-.32.17-.56.41-.73.72a2.3 2.3 0 0 0-.25 1.12h-2.17A3.69 3.69 0 0 1 21.48 98a4.85 4.85 0 0 1 2.21-.5c.85 0 1.59.17 2.23.48.64.32 1.13.75 1.48 1.3.36.55.54 1.17.54 1.88 0 .47-.1.93-.27 1.39-.18.45-.5.95-.95 1.5-.45.56-1.09 1.23-1.9 2.02l-2.17 2.2v.09h5.48v1.9H19.5ZM23.63 190.63a5.8 5.8 0 0 1-2.36-.45 3.95 3.95 0 0 1-1.62-1.27c-.4-.54-.6-1.16-.63-1.87h2.31c.02.34.13.63.34.89.2.25.48.44.82.58a3 3 0 0 0 1.14.21c.45 0 .85-.08 1.2-.23.34-.16.62-.38.81-.67.2-.28.3-.6.3-.97 0-.38-.1-.72-.3-1.01-.2-.3-.5-.52-.87-.68a3.4 3.4 0 0 0-1.37-.25H22.3v-1.76h1.11c.44 0 .82-.07 1.15-.22.33-.16.6-.37.78-.64.19-.28.28-.6.28-.97 0-.35-.08-.66-.24-.93a1.62 1.62 0 0 0-.68-.62 2.71 2.71 0 0 0-2.1-.01c-.33.14-.6.34-.8.6-.2.25-.3.55-.31.9h-2.2c.02-.7.22-1.32.61-1.85.4-.53.92-.95 1.58-1.25a5.2 5.2 0 0 1 2.2-.46c.84 0 1.57.16 2.19.48.62.3 1.1.73 1.44 1.26.34.52.51 1.1.51 1.75 0 .7-.2 1.3-.62 1.78s-.97.8-1.65.94v.1a3.2 3.2 0 0 1 2.03.98c.48.53.71 1.19.71 1.97 0 .7-.2 1.34-.6 1.9a4 4 0 0 1-1.65 1.3c-.7.31-1.5.47-2.4.47Z"
                fill="#5950EC"
            />
        </svg>
    )
}
