import React from 'react'

export default function Notification() {
    return (
        <svg style={{ maxWidth: '7rem' }} className="notification" viewBox="0 0 76 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.7572 18.3369C30.066 18.3369 24.6078 20.5977 20.5835 24.6221C16.5592 28.6464 14.2984 34.1045 14.2984 39.7957V52.621L11.7698 55.1495C11.2698 55.6497 10.9293 56.2869 10.7913 56.9806C10.6534 57.6742 10.7242 58.3932 10.9949 59.0466C11.2655 59.7001 11.7238 60.2586 12.3118 60.6516C12.8998 61.0446 13.5911 61.2544 14.2984 61.2546H57.216C57.9233 61.2544 58.6146 61.0446 59.2026 60.6516C59.7906 60.2586 60.2489 59.7001 60.5195 59.0466C60.7901 58.3932 60.861 57.6742 60.723 56.9806C60.5851 56.2869 60.2446 55.6497 59.7446 55.1495L57.216 52.621V39.7957C57.216 34.1045 54.9552 28.6464 50.9309 24.6221C46.9066 20.5977 41.4484 18.3369 35.7572 18.3369ZM35.7572 75.5604C32.9116 75.5604 30.1825 74.43 28.1703 72.4179C26.1582 70.4057 25.0278 67.6766 25.0278 64.831H46.4866C46.4866 67.6766 45.3562 70.4057 43.344 72.4179C41.3319 74.43 38.6028 75.5604 35.7572 75.5604Z"
                fill="#01FF94"
            />
            <circle cx="53.6471" cy="20.1178" r="15.6471" fill="#5950EC" />
            <path d="M56.38 9.49198V29H53.4271V12.4449H53.3128L48.6454 15.493V12.6735L53.5128 9.49198H56.38Z" fill="white" />
        </svg>
    )
}
