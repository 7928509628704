import React from 'react'

export default function Fingerprint() {
    return (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="63.2394" height="63.2394" rx="31.6197" fill="#01FF94" />
            <g clipPath="url(#clip0_1080_12811)">
                <path
                    d="M35.3052 14.3707C32.8151 13.7495 30.1949 13.6608 27.6454 14.2112C27.4483 14.2537 27.3231 14.4479 27.3656 14.645C27.4081 14.842 27.6024 14.9673 27.7994 14.9247C30.2411 14.3976 32.7563 14.4845 35.1487 15.0841C40.87 16.5181 45.6322 20.8197 47.4461 26.4388C48.1475 28.6119 48.4455 30.9258 48.5052 33.3508C48.5101 33.5523 48.6775 33.7117 48.879 33.7067C49.0806 33.7018 49.2399 33.5344 49.2349 33.3329C49.1745 30.8746 48.873 28.5011 48.1542 26.2563C46.2705 20.3736 41.2932 15.8643 35.3052 14.3707Z"
                    fill="#5950EC"
                />
                <path
                    d="M25.7931 15.4749C25.9874 15.4213 26.1015 15.2203 26.0479 15.026C25.9943 14.8317 25.7933 14.7176 25.599 14.7713C22.9327 15.5068 19.1819 18.0485 16.8639 21.4439C16.863 21.4452 16.8621 21.4466 16.8612 21.4479C14.8258 24.5152 13.55 28.3698 13.9062 32.1101C13.9065 32.1137 13.9069 32.1173 13.9074 32.1208C13.9927 32.8038 14.136 33.4855 14.2795 34.168C14.5817 35.6055 14.8847 37.0465 14.6486 38.5104C14.6165 38.7094 14.7518 38.8968 14.9508 38.9289C15.1498 38.961 15.3371 38.8257 15.3692 38.6267C15.6161 37.0964 15.3096 35.6038 15.003 34.1108C14.8612 33.4204 14.7194 32.7299 14.6324 32.0356C14.2973 28.5012 15.5041 24.8141 17.4681 21.8535C19.6979 18.5884 23.3087 16.1603 25.7931 15.4749Z"
                    fill="#5950EC"
                />
                <path
                    d="M30.5827 16.2904C35.1651 16.0106 39.7466 17.8807 42.8234 21.4635C42.9547 21.6165 42.9372 21.8469 42.7843 21.9782C42.6314 22.1096 42.4009 22.092 42.2696 21.9391C39.3436 18.532 34.984 16.7523 30.625 17.0192C30.6234 17.0192 30.6219 17.0193 30.6203 17.0194C25.1078 17.2861 19.9453 21.0257 18.0465 26.2658C17.9778 26.4554 17.7685 26.5533 17.579 26.4846C17.3894 26.416 17.2915 26.2067 17.3602 26.0171C19.3605 20.4969 24.7815 16.5721 30.5827 16.2904Z"
                    fill="#5950EC"
                />
                <path
                    d="M43.9353 23.0581C43.8229 22.8907 43.5962 22.8462 43.4288 22.9586C43.2615 23.0709 43.2169 23.2977 43.3293 23.465C45.035 26.0048 45.7403 28.7686 46.0191 32.0484C46.0362 32.2493 46.2128 32.3982 46.4137 32.3812C46.6145 32.3641 46.7635 32.1874 46.7465 31.9866C46.4615 28.6351 45.7342 25.7367 43.9353 23.0581Z"
                    fill="#5950EC"
                />
                <path
                    d="M46.5024 33.5362C46.7036 33.5236 46.8769 33.6765 46.8895 33.8777C47.027 36.0786 47.0283 38.5686 46.7848 40.4088C46.7584 40.6086 46.575 40.7492 46.3751 40.7227C46.1753 40.6963 46.0347 40.5129 46.0612 40.313C46.2958 38.5395 46.2971 36.1022 46.1609 33.9232C46.1484 33.722 46.3013 33.5488 46.5024 33.5362Z"
                    fill="#5950EC"
                />
                <path
                    d="M17.4953 28.0337C17.5426 27.8377 17.4221 27.6405 17.2262 27.5932C17.0302 27.5459 16.833 27.6664 16.7857 27.8623C16.5178 28.9719 16.3787 30.1117 16.3732 31.2525C16.3676 32.4284 16.5868 33.5762 16.8062 34.7245C16.9856 35.6638 17.1651 36.6034 17.2215 37.5589C17.2957 38.8141 17.0317 40.0711 16.5755 41.2674C16.5036 41.4558 16.5981 41.6667 16.7864 41.7385C16.9748 41.8103 17.1857 41.7159 17.2575 41.5275C17.7398 40.263 18.0321 38.9008 17.9502 37.5159C17.8942 36.5669 17.7147 35.6327 17.5353 34.699C17.3164 33.5596 17.0976 32.4207 17.1032 31.2561C17.1084 30.172 17.2406 29.0885 17.4953 28.0337Z"
                    fill="#5950EC"
                />
                <path
                    d="M27.0724 19.9229C27.1475 20.1099 27.0568 20.3225 26.8697 20.3976C23.0858 21.9172 20.2444 25.3463 19.6647 29.3899C19.4225 31.0793 19.6842 32.7527 19.9457 34.4257C20.093 35.3679 20.2403 36.3099 20.2976 37.2545C20.4217 39.3028 20.1884 41.5527 18.8554 43.5998C18.7454 43.7687 18.5193 43.8165 18.3504 43.7065C18.1814 43.5965 18.1337 43.3704 18.2437 43.2014C19.4582 41.3363 19.6881 39.2647 19.5689 37.2987C19.5108 36.3402 19.3645 35.3873 19.2182 34.435C18.9558 32.7264 18.6936 31.0197 18.9421 29.2863C19.561 24.9688 22.5873 21.3307 26.5977 19.7202C26.7848 19.6451 26.9973 19.7358 27.0724 19.9229Z"
                    fill="#5950EC"
                />
                <path
                    d="M39.8856 22.0009C36.9005 19.2049 32.5739 18.2291 28.6418 19.0916C28.4449 19.1348 28.3203 19.3294 28.3635 19.5263C28.4067 19.7232 28.6013 19.8478 28.7982 19.8046C32.5216 18.9879 36.5956 19.9195 39.3866 22.5336C41.739 24.737 43.1051 27.943 43.4627 31.4409C43.4832 31.6414 43.6624 31.7874 43.8629 31.7669C44.0634 31.7464 44.2094 31.5672 44.1889 31.3667C43.8176 27.7345 42.3938 24.3501 39.8856 22.0009Z"
                    fill="#5950EC"
                />
                <path
                    d="M44.1125 33.1049C44.313 33.0843 44.4923 33.2301 44.5129 33.4307C44.8595 36.8057 44.7101 40.451 44.1868 43.9775C44.1572 44.1769 43.9716 44.3145 43.7722 44.2849C43.5728 44.2554 43.4352 44.0697 43.4647 43.8703C43.9807 40.3929 44.1261 36.8095 43.7867 33.5052C43.7661 33.3047 43.912 33.1255 44.1125 33.1049Z"
                    fill="#5950EC"
                />
                <path
                    d="M31.3877 21.2644C25.7947 21.2644 21.2503 25.7264 21.2503 31.2432C21.2503 31.8325 21.3026 32.4099 21.4023 32.9713C21.4376 33.1698 21.627 33.3021 21.8255 33.2669C22.024 33.2316 22.1563 33.0422 22.1211 32.8437C22.0288 32.3239 21.9803 31.7892 21.9803 31.2432C21.9803 26.1408 26.1865 21.9943 31.3877 21.9943C33.7381 21.9943 35.8855 22.8415 37.5338 24.2413C37.6875 24.3717 37.9178 24.353 38.0483 24.1993C38.1788 24.0457 38.16 23.8153 38.0063 23.6849C36.2301 22.1764 33.9164 21.2644 31.3877 21.2644Z"
                    fill="#5950EC"
                />
                <path
                    d="M38.8241 25.0457C38.9696 24.9062 39.2007 24.911 39.3402 25.0565C40.9509 26.7362 41.4665 29.3762 41.8077 31.5917C42.192 34.0873 42.2507 36.5219 42.2507 37.6507C42.2507 37.8523 42.0873 38.0157 41.8857 38.0157C41.6841 38.0157 41.5207 37.8523 41.5207 37.6507C41.5207 36.5401 41.4626 34.1467 41.0862 31.7028C40.7695 29.646 40.3129 27.1256 38.8133 25.5618C38.6738 25.4163 38.6786 25.1852 38.8241 25.0457Z"
                    fill="#5950EC"
                />
                <path
                    d="M42.158 39.741C42.174 39.54 42.0241 39.3641 41.8232 39.3481C41.6222 39.3321 41.4464 39.482 41.4303 39.683C41.2511 41.9312 41.0169 44.177 40.5416 46.2515C40.4966 46.448 40.6194 46.6438 40.8159 46.6888C41.0124 46.7338 41.2081 46.611 41.2532 46.4145C41.7409 44.2853 41.9783 41.9952 42.158 39.741Z"
                    fill="#5950EC"
                />
                <path
                    d="M21.9898 34.3147C22.1876 34.2757 22.3795 34.4045 22.4184 34.6023C23.1902 38.5212 22.657 42.0812 20.6134 45.1997C20.5029 45.3683 20.2767 45.4154 20.1081 45.3049C19.9395 45.1944 19.8924 44.9682 20.0029 44.7996C21.9257 41.8654 22.4432 38.5061 21.7022 34.7433C21.6633 34.5455 21.792 34.3536 21.9898 34.3147Z"
                    fill="#5950EC"
                />
                <path
                    d="M31.3877 23.6391C27.7571 23.6391 24.1954 26.4495 23.8319 29.9816C23.6727 31.5279 23.8963 33.051 24.12 34.5748C24.3153 35.9053 24.5106 37.2363 24.4513 38.5836C24.3156 41.6671 23.4109 44.2124 21.8563 46.3088C21.7362 46.4707 21.7702 46.6993 21.9321 46.8193C22.094 46.9394 22.3226 46.9055 22.4427 46.7436C24.0914 44.5201 25.039 41.8316 25.1806 38.6157C25.2396 37.2745 25.0437 35.9477 24.8478 34.6218C24.6239 33.1056 24.4001 31.5905 24.558 30.0563C24.8774 26.9534 28.0711 24.3691 31.3877 24.3691C32.9345 24.3691 36.9368 25.2067 38.2099 29.354C39.0077 31.9529 39.2253 35.4702 39.0321 38.8873C38.8389 42.3039 38.2378 45.5717 37.4296 47.6672C37.357 47.8552 37.4507 48.0665 37.6387 48.139C37.8268 48.2116 38.0381 48.1179 38.1106 47.9299C38.9562 45.7375 39.5653 42.387 39.7609 38.9285C39.9565 35.4704 39.7415 31.8558 38.9077 29.1398C37.4945 24.5361 33.0645 23.6391 31.3877 23.6391Z"
                    fill="#5950EC"
                />
                <path
                    d="M25.7708 45.2002C25.9397 45.3104 25.9873 45.5365 25.8771 45.7053C25.6508 46.0523 25.4517 46.4154 25.2526 46.7785C25.0535 47.1416 24.8543 47.5047 24.628 47.8518C24.5178 48.0207 24.2917 48.0683 24.1229 47.9581C23.954 47.848 23.9064 47.6219 24.0165 47.453C24.2429 47.106 24.442 46.743 24.6411 46.3799C24.8402 46.0168 25.0393 45.6536 25.2657 45.3065C25.3758 45.1377 25.602 45.0901 25.7708 45.2002Z"
                    fill="#5950EC"
                />
                <path
                    d="M28.8961 27.4279C29.0636 27.3158 29.1086 27.0891 28.9965 26.9216C28.8845 26.754 28.6578 26.709 28.4902 26.8211C27.0449 27.7878 26.1582 29.1171 26.1582 31.0645C26.1582 32.2241 26.3637 33.1719 26.5744 34.1441C26.8896 35.5988 27.1035 37.0504 27.0651 38.5447C27.0184 40.3591 26.6634 42.0172 25.9809 43.5966C25.901 43.7817 25.9862 43.9965 26.1712 44.0765C26.3563 44.1564 26.5711 44.0712 26.651 43.8862C27.3726 42.2161 27.7459 40.4656 27.7948 38.5634C27.8347 37.0107 27.6149 35.5017 27.2879 33.9897C27.0767 33.0133 26.8882 32.1416 26.8882 31.0645C26.8882 29.396 27.6222 28.2799 28.8961 27.4279Z"
                    fill="#5950EC"
                />
                <path
                    d="M30.3206 26.1455C31.8352 25.8367 33.465 26.2182 34.6647 27.1892C36.8075 28.9236 37.0924 32.0865 37.3234 34.6495L37.3351 34.7801C37.6336 38.0839 37.292 41.293 36.6039 44.5157C36.5618 44.7128 36.3678 44.8385 36.1707 44.7964C35.9736 44.7543 35.8479 44.5604 35.89 44.3632C36.5678 41.1888 36.8981 38.0562 36.6081 34.8458C36.5985 34.7395 36.589 34.6317 36.5793 34.5226L36.5792 34.521C36.374 32.2048 36.1171 29.3039 34.2054 27.7566C33.1798 26.9265 31.7731 26.5943 30.4664 26.8607C30.2689 26.901 30.0762 26.7735 30.0359 26.576C29.9956 26.3785 30.1231 26.1857 30.3206 26.1455Z"
                    fill="#5950EC"
                />
                <path
                    d="M36.088 46.3318C36.1377 46.1365 36.0197 45.9378 35.8243 45.8881C35.629 45.8383 35.4303 45.9564 35.3806 46.1517C35.1599 47.0184 34.8199 47.8007 34.3888 48.521C34.2853 48.6939 34.3416 48.9181 34.5146 49.0216C34.6875 49.1251 34.9117 49.0688 35.0152 48.8959C35.4798 48.1197 35.8486 47.2722 36.088 46.3318Z"
                    fill="#5950EC"
                />
                <path
                    d="M34.3032 41.0352C34.5034 41.0589 34.6464 41.2404 34.6227 41.4405C34.278 44.3535 33.4998 46.9515 32.2511 49.2048C32.1534 49.3811 31.9313 49.4448 31.7549 49.3471C31.5786 49.2494 31.5149 49.0273 31.6126 48.851C32.8077 46.6945 33.5622 44.1909 33.8978 41.3548C33.9215 41.1546 34.103 41.0115 34.3032 41.0352Z"
                    fill="#5950EC"
                />
                <path
                    d="M34.228 30.8731C33.9749 29.521 32.8145 28.4868 31.4057 28.4868C29.4981 28.4868 28.1596 30.4097 28.6313 32.1987C29.4294 35.3566 29.5604 38.4001 29.0201 41.3314L29.0197 41.3336C28.5509 43.9654 27.6506 46.3331 26.4225 48.3394C26.3173 48.5113 26.3713 48.736 26.5433 48.8412C26.7152 48.9465 26.9399 48.8924 27.0451 48.7205C28.3234 46.6321 29.2543 44.1781 29.7382 41.4626C30.2991 38.4182 30.1601 35.268 29.3385 32.0179L29.3376 32.0141C28.9838 30.6759 29.9699 29.2168 31.4057 29.2168C32.4419 29.2168 33.3168 29.979 33.51 31.0049C34.0413 34.0865 34.318 36.9441 34.1209 39.4044C34.1048 39.6054 34.2547 39.7813 34.4556 39.7974C34.6566 39.8135 34.8325 39.6636 34.8486 39.4627C35.0528 36.9125 34.7645 33.9845 34.2289 30.8782C34.2286 30.8765 34.2283 30.8748 34.228 30.8731Z"
                    fill="#5950EC"
                />
                <path
                    d="M30.3166 46.6809C30.4959 46.7731 30.5666 46.9931 30.4744 47.1724L29.5073 49.0545C29.4152 49.2337 29.1952 49.3044 29.0159 49.2123C28.8366 49.1201 28.7659 48.9001 28.8581 48.7208L29.8252 46.8388C29.9173 46.6595 30.1373 46.5888 30.3166 46.6809Z"
                    fill="#5950EC"
                />
                <path
                    d="M31.6772 31.225C31.6311 31.0288 31.4346 30.9071 31.2384 30.9532C31.0422 30.9993 30.9205 31.1957 30.9666 31.392C32.1974 36.6314 32.0283 41.2321 30.5291 45.1512C30.4571 45.3395 30.5513 45.5505 30.7396 45.6225C30.9279 45.6946 31.1389 45.6003 31.2109 45.4121C32.7741 41.3256 32.933 36.5708 31.6772 31.225Z"
                    fill="#5950EC"
                />
            </g>
            <defs>
                <clipPath id="clip0_1080_12811">
                    <rect width="35.5462" height="35.5462" fill="white" transform="translate(13.8467 13.8467)" />
                </clipPath>
            </defs>
        </svg>
    )
}
