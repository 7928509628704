import React from 'react'

export default function Globe() {
    return (
        <svg style={{ maxWidth: '8rem' }} viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M55.001 0C85.3558 0 110 24.643 110 54.9963C110 85.3496 85.3558 109.993 55.001 109.993C24.6462 109.993 0.00170898 85.3496 0.00170898 54.9963C0.00170898 24.643 24.6462 0 55.001 0Z"
                fill="#01FF94"
            />
            <mask id="mask0_1061_12259" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="110" height="110">
                <path
                    d="M54.9993 0C85.3541 0 109.999 24.643 109.999 54.9963C109.999 85.3496 85.3541 109.993 54.9993 109.993C24.6445 109.993 0 85.3496 0 54.9963C0 24.643 24.6445 0 54.9993 0Z"
                    fill="#01FF94"
                />
            </mask>
            <g mask="url(#mask0_1061_12259)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M24.6594 5.08826C25.9421 5.94794 27.389 7.02355 28.915 7.33508C32.4209 8.0508 37.0968 5.9424 40.0029 8.67645C42.71 11.2234 40.2731 18.5769 38.0846 20.9618C36.5978 22.5819 34.5053 23.4652 32.9354 24.9996C32.089 25.8268 30.6738 28.3327 29.4823 28.4475C28.4466 28.5475 27.2218 27.1272 26.6316 26.4225C23.1036 22.2094 26.0565 17.5663 20.1377 13.7544C19.6121 13.4159 18.3815 12.8084 18.1511 12.3233C17.8152 11.6165 23.5513 6.0786 24.659 5.08789L24.6594 5.08826Z"
                    fill="#5950EC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M-1.86328 50.7994C1.77068 53.0957 5.90628 54.1964 9.01832 57.304C12.0827 60.3643 8.29595 62.1963 9.19734 65.584C10.1286 69.0844 12.7693 71.5862 13.0583 75.3767C13.4079 79.9623 12.6091 84.0093 14.5411 88.4081C14.7212 88.8178 16.3154 91.4643 18.1865 93.3129C19.973 95.078 22.0467 96.0691 21.9437 95.6099C21.4591 93.4476 20.2794 90.1252 21.1737 89.0112C22.9285 86.8245 21.6636 84.8564 23.3083 82.5741C24.787 80.5226 28.1825 78.0801 30.8018 75.1368C33.6724 71.9107 36.3644 68.9412 35.411 66.9431C34.4646 64.9602 28.5982 61.7603 26.5939 60.2602C21.6923 56.5916 21.2952 52.1437 14.6212 55.0394C14.1118 55.2605 11.8568 56.8736 11.3582 56.7448C11.336 56.7388 11.3548 55.6828 11.3434 55.5267C11.2747 54.6138 10.9004 53.6095 10.2648 52.9517C9.07811 51.7244 6.45221 52.2589 5.88783 50.4646C4.79376 46.986 8.42367 44.9086 11.6878 45.6122C12.0344 45.6867 12.8487 45.1832 13.1259 45.7163C13.7157 46.8506 13.8722 47.9978 14.7603 47.4142C15.0774 47.2056 14.4665 45.9503 14.087 45.1862C13.8693 44.7481 14.5477 43.9223 14.8548 43.5529C16.1822 41.9557 17.0455 40.0389 18.4068 38.4982C19.7098 37.0235 21.4897 36.138 22.6893 34.5239C23.1817 33.8613 23.494 33.0732 23.6258 32.2494C23.9613 30.1531 16.5336 22.536 13.7264 31.0959C13.5898 31.5126 13.4093 33.1751 13.0029 32.8185C9.27411 29.5482 10.8562 27.8927 12.8475 24.413C13.7139 22.8989 13.4271 21.0201 15.5668 21.4479C17.7203 21.8786 18.7246 25.2775 20.5126 25.0896C21.2815 25.0087 22.672 24.527 22.9385 23.6829C24.2452 19.5436 20.0191 20.8909 18.2245 18.2007C17.4626 17.0587 16.8492 15.8199 16.0696 14.6864C16.0519 14.6605 15.9001 14.4018 15.8688 14.4073C6.5622 16.01 -0.593884 41.3197 -1.86291 50.7986L-1.86328 50.7994Z"
                    fill="#5950EC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M98.2219 13.4492C94.6274 13.2919 91.0983 12.4452 87.5226 13.32C81.8235 14.7145 77.517 19.2067 71.7657 20.4657C66.0544 21.7159 60.1673 12.8025 52.6336 24.4884C52.046 25.3997 52.9337 26.5521 53.6882 27.2911C57.358 30.8863 57.2594 21.1515 60.4512 24.1447C60.914 24.5788 61.3241 25.3281 61.275 25.9814C60.9343 30.5257 54.5881 30.082 52.0131 32.2875C50.9146 33.2283 51.1387 35.1153 50.0221 35.9683C48.5611 37.0845 43.1048 37.2273 45.0837 40.0843C45.3509 40.47 45.6665 40.8233 46.0275 41.1186C48.6132 43.2336 51.8839 38.7843 54.1208 38.0564C56.7899 37.1882 58.2409 40.4564 60.4176 39.5325C64.6971 37.7164 64.995 34.1268 68.5105 38.5924C68.6714 38.7965 67.7139 38.6304 67.5061 38.6145C66.8554 38.5643 64.7599 38.1202 64.3631 38.7688C63.4019 40.3394 65.7225 45.4358 66.3253 46.7547C66.7767 47.7432 67.8483 52.6133 69.0058 53.4874C71.1921 55.1384 77.1667 54.6789 77.1438 53.1385C77.1143 51.1435 76.0678 49.2798 74.9859 48.3563C74.4341 47.8853 71.2512 45.1387 71.576 44.389C71.6004 44.3325 72.1559 44.3698 72.2353 44.3727C75.1686 44.485 81.1834 49.88 82.0346 52.486C82.221 53.057 82.4857 54.7405 83.1774 56.1214C84.2748 58.3128 85.553 61.3278 86.4544 61.8556C86.667 61.9804 87.712 60.3153 88.1874 59.1087C88.7271 57.7389 88.778 55.7825 89.1383 55.0772C89.4834 54.4017 90.2368 53.8598 90.762 53.435C91.6523 52.7152 92.9649 51.8636 93.4702 51.9806C98.3692 53.116 95.18 57.8622 96.9097 56.4975C98.8712 54.9498 101.47 58.4206 102.545 61.5076C102.603 61.674 103.356 65.3892 103.751 66.3216C104.226 67.4444 108.751 70.1508 109.131 68.437C109.57 66.4593 105.391 64.345 104.371 62.76C102.914 60.4973 105.392 57.6721 105.728 57.4377C106.41 56.9622 106.475 55.7681 106.438 54.4065C106.403 53.123 103.275 52.1781 103.293 51.524C103.345 49.695 111.558 54.5013 111.788 50.338C112.221 42.5334 103.978 43.7017 104.257 41.841C104.582 39.675 107.249 42.0632 108.415 41.3988C110.93 39.9644 111.625 33.6809 110.716 31.1971C108.553 25.2849 101.051 19.0431 98.2223 13.4473L98.2219 13.4492Z"
                    fill="#5950EC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M56.3569 74.757C55.4529 71.1219 52.0666 65.7712 50.486 62.62C48.048 57.7602 43.4411 63.7226 40.3825 59.1034C36.5448 53.3075 40.5191 45.3911 46.5324 43.4779C47.4113 43.1981 49.4975 41.6899 50.8208 41.6663C52.1397 41.6427 52.6952 43.1036 54.1514 43.0431C56.125 42.9604 58.0264 42.2691 59.7609 42.7349C62.4469 43.4561 63.9452 45.7517 64.8355 47.6453C65.8938 49.8958 66.4995 54.4311 69.775 56.4668C72.0872 57.9034 71.1736 56.651 72.5633 56.7928C73.9689 56.9363 74.9478 55.8866 74.1849 57.1434C72.4943 59.9277 70.9396 63.2121 69.8673 66.6331C69.2059 68.7426 69.8562 71.5434 69.3948 73.6618C68.637 77.1422 66.6353 79.6958 62.8544 82.6934C61.7534 83.5663 59.2681 84.8169 58.0138 83.8118C56.1996 82.3582 56.8448 76.7166 56.3576 74.7577L56.3569 74.757Z"
                    fill="#5950EC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M71.4132 81.7962C70.3354 81.3714 71.9045 79.4261 71.7649 78.4044C71.6981 77.9149 71.2545 77.1963 71.5424 76.7519C72.1104 75.8748 73.1573 75.5699 73.7999 74.7833C73.9778 74.5656 74.2465 73.5664 74.4506 73.4733C74.5115 73.4457 74.9338 74.2644 74.9726 74.3434C75.4469 75.3097 75.0305 76.1605 74.7762 77.1612C74.5344 78.1139 74.533 79.1223 74.1542 80.0414C73.5319 81.5533 72.14 82.0826 71.4132 81.7962Z"
                    fill="#5950EC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M38.8238 23.8168C39.9028 23.5363 44.1288 22.4899 44.9017 25.0604C45.0199 25.4539 45.1173 25.8337 44.8896 26.1991C43.7571 28.0185 38.9992 29.3348 38.4278 26.7447C38.2166 25.7879 37.894 24.059 38.8238 23.8172V23.8168Z"
                    fill="#5950EC"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.3762 49.7144C12.6452 49.4546 15.9215 49.3224 17.4722 49.4217C19.3543 49.5424 20.8094 50.37 22.5114 50.793C22.6096 50.8174 22.7343 50.8458 22.8333 50.8679C24.0336 51.1344 26.7116 51.0813 26.7385 51.9576C26.7463 52.2137 26.3425 52.351 25.8733 52.3928C23.4445 52.6072 21.032 51.151 18.2621 51.1049C16.5276 51.0761 12.0133 51.0362 11.3766 49.7148L11.3762 49.7144Z"
                    fill="#5950EC"
                />
            </g>
        </svg>
    )
}
