import React from 'react'

export default function Lock() {
    return (
        <svg viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="63.2394" height="63.2394" rx="31.6197" fill="#01FF94" />
            <path
                d="M40.5798 29.3789H22.6597C21.4202 29.3789 20.4197 30.3794 20.4197 31.6189V45.059C20.4197 46.2985 21.4202 47.299 22.6597 47.299H40.5798C41.8193 47.299 42.8198 46.2985 42.8198 45.059V31.6189C42.8198 30.3794 41.8193 29.3789 40.5798 29.3789ZM32.3666 38.9815V43.5661H30.8734V38.9815C29.5891 38.653 28.6334 37.4883 28.6334 36.0993C28.6334 34.4565 29.9774 33.1125 31.6201 33.1125C33.2629 33.1125 34.6069 34.4565 34.6069 36.0993C34.6067 37.488 33.6509 38.6528 32.3666 38.9815Z"
                fill="#5950EC"
            />
            <path
                d="M39.8326 27.8863V22.6595C39.8326 18.9544 36.8176 15.9395 33.1126 15.9395H30.1258C26.421 15.9395 23.4058 18.9544 23.4058 22.6595V27.8863H26.3925L26.3923 22.6595C26.3923 20.6018 28.0663 18.9262 30.1256 18.9262H33.1123C35.1716 18.9262 36.8456 20.6018 36.8456 22.6595V27.8863H39.8326Z"
                fill="#5950EC"
            />
        </svg>
    )
}
